/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-123vcn5 --parallax pb--80 pt--80" name={"einleitung"} style={{"minHeight":"100vh"}} layout={"l1"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/0a98a9962fd942ddaa05c7acf16389c0_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 pb--80 pl--40 pr--40 pt--80" anim={"2"} animS={"2"} style={{"maxWidth":800,"backgroundColor":"rgba(248, 244, 244, 1)"}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box swpf--uppercase" content={"Frohe<br>Weihnachten<br>"}>
              </Title>

              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/ca7f823b35a54b9d942e61668b7deb2d.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":360}} srcSet={""} content={null}>
              </Image>

              <Subtitle className="subtitle-box swpf--uppercase mt--30" content={"fröhliche<br>Neues Jahr"}>
              </Subtitle>

              <Text className="text-box mt--40" style={{"maxWidth":366}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 swpf--uppercase mt--20" innerClassName="pl--0 pr--0" href={"/de/Nachricht-hinterlassen "} content={"Nachricht hinterlassen"}>
              </Button>

              <Text className="text-box" content={"Erstellt mit <a href=\"https://saywebpage.com/de/neujahrskarten-2021/\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}